<template>
    <BT-Blade-Items
        addBladeName="zone"
        :bladesData="bladesData"
        bladeName="zones"
        canAdd
        :headers="[
            { text: 'Zone Name', value: 'zoneName', title: 1 }]"
        hideActions
        :itemProperties="['ID','ZoneName']"
        navigation="zones"
        useServerPagination
        title="Zones" />
</template>

<script>
export default {
    name: 'Zones-Blade',
    props: {
        bladesData: null
    }
}
</script>